var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('date-range-picker',{key:("calendar-" + (_vm.$i18n.locale)),ref:"picker",staticClass:"w-100",attrs:{"opens":"right","auto-apply":true,"show-dropdowns":true,"locale-data":{
      format: 'YYYY-MM-DD',
      separator: ' - ',
      daysOfWeek: [
        _vm.$t('day.sun'),
        _vm.$t('day.mon'),
        _vm.$t('day.tue'),
        _vm.$t('day.wed'),
        _vm.$t('day.thu'),
        _vm.$t('day.fri'),
        _vm.$t('day.sat') ],
      monthNames: [
        _vm.$t('month.jan'),
        _vm.$t('month.feb'),
        _vm.$t('month.mar'),
        _vm.$t('month.apr'),
        _vm.$t('month.may'),
        _vm.$t('month.jun'),
        _vm.$t('month.jul'),
        _vm.$t('month.aug'),
        _vm.$t('month.sep'),
        _vm.$t('month.oct'),
        _vm.$t('month.nov'),
        _vm.$t('month.dec') ],
    },"ranges":_vm.customRanges},on:{"update":_vm.onChange},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(picker.startDate,"LL"))+" "),(picker.startDate || picker.endDate)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDate")(picker.endDate,"LL"))+" ")]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }