<template>
  <b-row>
    <b-col
      :cols="field.cols ? field.cols : 12"
      :md="field.md ? field.md : 4"
      :lg="field.lg ? field.lg : 3"
      v-for="field in fields"
      :key="field.key"
    >
      <slot :name="field.key" :field="field">
        <b-form-group :label="$t(field.label)" v-if="!field.hide">
          <b-form-input
            v-if="field.type === 'text'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            @change="onChange"
          />
          <n-tel-input
            v-if="field.type === 'tel'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            @input="onChange"
          ></n-tel-input>
          <n-currency-input
            v-if="field.type === 'currency'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :prefix="field.prefix"
            :suffix="field.suffix"
            @input="onChange"
          ></n-currency-input>
          <n-price-range-input
            v-if="field.type === 'price-range-input'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :first-placehoder="field.firstPlacehoder"
            :second-placehoder="field.secondPlacehoder"
            :prefix="field.prefix"
            :suffix="field.suffix"
          ></n-price-range-input>
          <b-form-datepicker
            v-if="field.type === 'date'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            class="mb-1"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }"
            v-bind="labels[$i18n.locale] || {}"
            :locale="$i18n.locale"
            :hide-header="true"
          />
          <n-date-picker
            v-if="field.type === 'date-picker'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :disabled="field.disabled"
            :disable-type="field.disableType"
          />
          <n-year-picker
            v-if="field.type === 'year-picker'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :disabled="field.disabled"
            :disable-type="field.disableType"
          />
          <n-year-range-picker
            v-if="field.type === 'year-range-picker'"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :disabled="field.disabled"
            :disable-type="field.disableType"
            v-model="data[field.key]"
          />
          <n-date-range-picker
            v-if="field.type === 'date-range-picker'"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :disabled="field.disabled"
            :disable-type="field.disableType"
            v-model="data[field.key]"
          />
          <n-async-multi-select
            v-if="field.type === 'async-multi-selection'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :localization="field.localization"
            :readonly="field.disabled"
            @change="onChange"
          ></n-async-multi-select>
          <n-async-single-select
            v-if="field.type === 'async-single-selection'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :clearable="field.clearable"
            :localization="field.localization"
            @change="onChange"
          ></n-async-single-select>
          <n-single-select
            v-if="field.type === 'single-selection'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :options="field.options"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :readonly="field.disabled"
            :translatable="field.translatable"
            :clearable="field.clearable"
            @input="onChange"
          ></n-single-select>
          <n-multi-select
            v-if="field.type === 'multi-selection'"
            v-model="data[field.key]"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :options="field.options"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :readonly="field.disabled"
            :translatable="field.translatable"
            @input="onChange"
          ></n-multi-select>
          <b-form-radio-group
            v-if="field.type === 'radio'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :disabled="field.disabled"
            @change="onChange"
            buttons
            button-variant="outline-primary rounded-0"
            class="flex-wrap"
          >
            <b-form-radio
              :value="option.value"
              v-for="option in field.options"
              :key="option.value"
              >{{ $t(option.text) }}</b-form-radio
            >
          </b-form-radio-group>
          <b-form-checkbox-group
            v-if="field.type === 'checkbox'"
            v-model="data[field.key]"
            :id="field.key"
            :ref="field.key"
            :disabled="field.disabled"
            @change="onChange"
            buttons
            button-variant="outline-primary rounded-0"
            class="flex-wrap"
          >
            <b-form-checkbox
              :value="option.value"
              v-for="option in field.options"
              :key="option.value"
            >
              {{ $t(option.text) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BFormDatepicker,
} from "bootstrap-vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";
import NSingleSelect from "@/components/NSingleSelect";
import NMultiSelect from "@/components/NMultiSelect";
import NDateInput from "@/components/NDateInput";
import NCurrencyInput from "@/components/NCurrencyInput";
import NTelInput from "@/components/NTelInput";
import NYearPicker from "@/components/NYearPicker";
import NDatePicker from "@/components/NDatePicker";
import NYearRangePicker from "./NYearRangePicker.vue";
import NDateRangePicker from "./NDateRangePicker.vue";
import NPriceRangeInput from "./NPriceRangeInput.vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,

    NAsyncSingleSelect,
    NDateInput,
    BFormDatepicker,
    NSingleSelect,
    NMultiSelect,
    NCurrencyInput,
    NAsyncMultiSelect,
    NTelInput,
    NYearPicker,
    NYearRangePicker,
    NDatePicker,
    NDateRangePicker,
    NPriceRangeInput,
  },
  props: {
    value: {},
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},

      labels: {
        en: {
          labelHelp: "",
        },
        km: {
          labelNoDateSelected: "មិនទាន់ជ្រើសរើសថ្ងៃ",
          labelHelp: "",
        },
      },
    };
  },
  computed: {},
  mounted() {
    if (this.value) {
      this.data = this.value;
    }
  },
  methods: {
    reset() {
      this.fields.forEach((element) => {
        if (element.key) {
          if (
            this.$refs[element.key] &&
            this.$refs[element.key].length > 0 &&
            typeof this.$refs[element.key][0].reset === "function"
          ) {
            this.$refs[element.key][0].reset();
          }
          if (element.type != "checkbox") {
            this.data[element.key] = null;
          } else {
            this.data[element.key] = [];
          }
        }
      });
    },
    onChange() {
      this.$emit("input", this.data);
    },
  },
};
</script>

<style scoped>
</style>
